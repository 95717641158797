.white {
    background: #FFFFFF;
}

.white-text {
    color: #FFFFFF;
}

.white-smoke {
    background: #F7F7F7;
}

.white-smoke-text {
    color: #F7F7F7;
}

.platinum {
    background: #E9E8E9;
}

.platinum-text {
    color: #E9E8E9;
}

.quick-silver {
    background: #A2A2A2;
}

.quick-silver-text {
    color: #A2A2A2;
}

.cyan {
    background: #008BD0;
}

.cyan-text {
    color: #008BD0;
}

.black {
    background: #000000;
}

.black-text {
    color: #000000;
}

.yellow-text {
    color: #DEDE4C;
}

.error {
    background: #FF647C;
}

.success {
    background: #00C48C;
}

.error-text {
    color: #FF647C;
}

.success-text {
    color: #00C48C;
}

.bold {
    font-weight: bold !important;
}
