* {
    /* Text & Font sizes*/
    --headline-font-size: 22px;
    --headline-text-font-size: 24pt;
    --subline-font-size: 18px;
    // --text-font-size: 14px;
    --text-font-size: 16pt;
    --ui-text-font-size: 14px;
    --tiny-text-font-size: 12px;

    /* Header and Footer */
    --header-height: 64px;
    --footer-height: 32px;
    --viewer-footer-height: 64px;
    --viewer-slider-tabs-height: 48px;
    --frame-slider-bottom: 96px;

    /* Content sizes */
    --mdc-list-list-item-leading-avatar-size: 96px;
    --zeiss-logo-size: 44px;
    --menu-list-item: 48px;

    /* Padding */
    --padding-medium: 48px;
    --padding-small: 24px;
    --padding-tiny: 12px;

    /* Margin */
    --margin-huge: 64px;
    --margin-large: 48px;
    --margin-medium: 24px;
    --margin-small: 12px;
    --margin-tiny: 8px;
}

.only-on-landscape {
    display: none;
}


/* SMALL-Devices (Smartphone in portrait mode) */
@media only screen and (min-width: 0px ) and (orientation:portrait) {
    *{
        --mdc-list-list-item-leading-avatar-size: 48px;

        /* Text & Font sizes */
        --headline-font-size: 14pt;
        --subline-font-size: 12pt;
        --headline-text-font-size: 20pt;
        --text-font-size: 10pt;
        --tiny-text-font-size: 8pt;

        /* Header and Footer */
        --frame-slider-bottom: 32px;
        --header-height: 56px;

        /* Padding */
        --padding-medium: 24px;
        // --padding-small: 12px;
        // --padding-tiny: 8px;

        /* Margin */
        --margin-large: 24px;
        --margin-medium: 12px;
        --margin-small: 6px;
        --margin-tiny: 4px;
        
    }
}

/* Mobile-Devices in Landscape Mode */
@media only screen and (min-width: 640px) and (max-width: 979px) and (orientation:landscape) {
    * {
        // outline: 1px solid rgba(255, 255, 0, .4);

        /* Text & Font sizes*/
        // --headline-font-size: 18px;
        // --subline-font-size: 14px;
        // --text-font-size: 12px;
        // --tiny-text-font-size: 8px;
        --headline-font-size: 16pt;
        --subline-font-size: 14pt;
        --text-font-size: 12pt;
        --tiny-text-font-size: 8pt;

        /* Header and Footer */
        --header-height: 48px;
        --footer-height: calc(32px / 2);
        --viewer-footer-height: 32px;
        --viewer-slider-tabs-height: 32px;
        --frame-slider-bottom: calc(32px / 2);

        /* Content sizes */
        --mdc-list-list-item-leading-avatar-size: 48px;
        --zeiss-logo-size: 32px;
        --menu-list-item: 36px;

        /* Padding */
        --padding-medium: calc(48px / 2);
        --padding-small: calc(24px / 2);
        --padding-tiny: 12px;

        /* Margin */
        --margin-large: 48px;
        --margin-medium: 24px;
        --margin-small: 12px;
        --margin-tiny: 8px;
    }

    .hide-on-landscape {
        display: none;
    }

    .only-on-landscape {
        display: block;
    }
}

/* LARGE-Devices */
@media only screen and (min-width: 980px ) and (orientation:landscape) {

}

/* X-LARGE-Devices */
@media only screen and (min-width: 1200px ) and (orientation:landscape) {

}

/* MAX-Devices */
@media only screen and (min-width: 1800px ) and (orientation:landscape) {

}