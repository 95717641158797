/*******************************************\
** Media Queries for Mobile Device Support ** 
\*******************************************/

/* SMALL-Devices */
@media only screen and (min-width: 0px ) {
}

/* MEDIUM-Devices */
@media only screen and (min-width: 640px ) {


    .login-btn-wrapper {
        width: auto;
    }

    .login-btn-wrapper button {
        width: auto;
    }

}

/* LARGE-Devices */
@media only screen and (min-width: 980px ) {

}

/* X-LARGE-Devices */
@media only screen and (min-width: 1200px ) {
}

/* MAX-Devices */
@media only screen and (min-width: 1800px ) {
}