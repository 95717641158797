/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "ZEISSFrutigerNextUI-Reg";
    src: url("./assets/fonts/FrutNextUI/ZEISSFrutigerNextUI-Reg.ttf") format("truetype");
}

.debug-outline * {
    outline: 1px solid rgba(255, 0, 0, 0.5);
}

* {
    margin: 0;
    padding: 0;
    font-family: "ZEISSFrutigerNextUI-Reg", "Roboto", "Helvetica Neue", sans-serif !important;
    font-weight: 300;
}

html,
body {
    height: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#fixiphone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
}

mat-icon {
    font-family: "Material Icons" !important;
}

/* Override Truste (Cookie Manager) Style */
.truste_box_overlay {
    top: 48px;
    bottom: 48px;
    max-height: 85%;
    overflow: scroll;
    overflow-x: hidden;
}

.mat-drawer-side.mat-drawer-end {
    border-left: none;
}

mat-drawer-container.viewer {
    border-bottom: 1px solid #a2a2a2;

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

mat-drawer-content.viewer,
mat-drawer-content.gallery {
    border-top: 1px solid #a2a2a2;

    overflow: hidden !important;
}

.avatar-toggle-btn button .mat-mdc-button-focus-overlay {
    display: none !important;
}

.avatar-toggle-btn button mat-icon {
    font-size: 36px !important;
    height: auto !important;
    width: auto !important;
    margin-right: unset !important;
}

.avatar-toggle-btn button p {
    margin-right: 10px;
    white-space: nowrap;
}

mat-tab-header {
    background: #fff;
    position: absolute !important;
    z-index: 1000;

    width: 25vw;
    max-width: 25vw;
}

mat-label.selection,
mat-label.contact-label {
    font-size: var(--tiny-text-font-size) !important;
    -webkit-text-size-adjust: none;
}

// Gallery search field

vis-frame-gallery-search input {
    max-height: 32px !important;
    margin-top: 0px !important;
    font-size: var(--ui-text-font-size) !important;
}

vis-frame-gallery-search .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
}

vis-frame-gallery-search button[mat-icon-button] {
    height: 32px !important;
    width: 32px !important;
    padding: 4px !important;
}

vis-frame-gallery-search .mat-mdc-form-field-underline {
    display: none;
}

vis-frame-gallery-search .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: unset;
    display: flex;
    justify-content: center;
}

vis-frame-gallery-search [matformfieldlineripple], vis-frame-gallery-search .mat-mdc-form-field-focus-overlay {
    display: none;
}

 vis-frame-gallery-search .mdc-text-field--filled, vis-frame-gallery-search .mdc-text-field--focused {
    background: transparent !important;
 }

 vis-frame-gallery-search .mat-mdc-text-field-wrapper.mdc-text-field {
    padding-right: 0px;
 }

vis-frame-gallery-search {
    height: 40px;
    display: flex;
    align-items: center;
}

mat-checkbox.mat-mdc-checkbox label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #A2A2A2;
}
mat-checkbox.mat-mdc-checkbox .mdc-form-field {
    max-width: 100% !important;
    height: 24px;
}

.ngx-slider {
    .ngx-slider-selection {
        background-color: rgba(0, 139, 209, 0.4) !important;
    }

    .ngx-slider-pointer{
        width: 16px !important;
        height: 16px !important;
        top: -6px !important;
        background-color: #008bd0 !important;
    }

    .ngx-slider-pointer.ngx-slider-active:after {
        background-color: transparent !important;
        outline: transparent;
    }
}

.rotation-slider .ngx-slider {
    margin-top: 15px !important;
}

.custom-slider .ngx-slider.vertical .ngx-slider-pointer {
    top: auto !important;
    left: -6px !important;
}

.ngx-slider .ngx-slider-pointer:after {
    width: 0px !important;
    height: 0px !important;
}

.ngx-slider-pointer {
    &:focus {
        outline: 0 !important;
    }
}

.gallery-header .mat-fab .mat-mdc-button-wrapper {
    padding: 0px !important;
}

.gallery-filter > .filter-component {
    padding-bottom: 32px !important;
    padding-top: 7px !important;
}

.gallery-branding > p {
    line-height: normal !important;
    font-size: var(--tiny-text-font-size);
}

mat-action-list.color-dots > button[mat-list-item] > span > span {
    display: flex;
    align-items: center;
    gap: 12px;
}

.daylight-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.icon-wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
}

.avatar-container .mat-list-item-content, .avatar-container mat-list-item {
    min-height: fit-content;
}

.link-list .mdc-list-item__content {
    display: flex;
    flex-direction: column;
    gap: var(--margin-small);
}

.session-item .mdc-list-item__content {
    align-self: center !important;
}

div[matlistitemavatar]{
    align-self: center !important;
}

vis-frame-host button[mat-icon-button].mat-mdc-icon-button>.mat-mdc-button-touch-target {
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (min-width: 640px) and (max-width: 979px) and (orientation: landscape) {
    .mat-list-base .mat-list-item.mat-multi-line .mat-list-item-content,
    .mat-list-base .mat-list-option.mat-multi-line .mat-list-item-content {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .mat-menu-content:not(:empty) {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .nose_ud_menu {
        left: 10%;
        top: auto !important;
        bottom: calc(var(--viewer-footer-height) + var(--margin-small));
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
        top: 20px !important;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        padding: 8px 24px !important;
    }

    .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
        top: 20px !important;
    }
    .mat-menu-panel {
        min-height: unset !important;
    }
}

.mat-mdc-tab-body-wrapper {
    margin-top: var(--viewer-footer-height);
}

.mat-mcd-tab-body-content {
    overflow-y: hidden !important;
}

.mat-mdc-tab {
    width: calc(100% / 3) !important;
    opacity: 1 !important;
    height: var(--viewer-slider-tabs-height) !important;
    flex-grow: 0 !important;
}

.mat-mdc-tab .tab-text {
    font-weight: bold !important;
}

.mdc-tab--active .tab-text {
    color: var(--cyan) !important;
}

.nose_ud_menu {
    position: fixed !important;

    left: 5%;
    top: 25%;

    min-width: 48px !important;

    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu_left {
    position: fixed !important;
    left: 48px;
    top: 28%;
    min-width: 48px !important;
    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu_right {
    position: fixed !important;
    left: calc(50% + 48px);
    top: 28%;
    min-width: 48px !important;
    overflow: hidden !important;
    background: transparent;
    box-shadow: none !important;  
    background-color: rgba(0,0,0,0) !important;
}

.nose_ud_menu-back {
    background: transparent;   
}

.slider-wrapper {
    background: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
}
.position-slider {
    flex-direction: column;
    max-width: 48px;
    margin-bottom: 12px;
    box-shadow: rgba(0, 0, 0, .2) 10px 0px 10px;
}

.position-slider mat-icon {
    margin-top: -6px;
    margin-bottom: -6px;
}

.frame-icon-wrapper {
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.custom-slider {
    width: 44px;
    height: calc(33vh + 16px);
}

.rotation-slider {
    max-width: calc((128px * 1.5) + (2 * 48px) + 16px);
    box-shadow: rgba(0, 0, 0, .2) 0px 5px 5px;
}

.rotation-slider ngx-slider{
    min-width: 168px;
}

.rotation-slider mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
}

.rotation-slider i {
    margin-top: -4px;
}

.rotation-slider > .frame-icon-wrapper {
    box-sizing: border-box;
    padding-bottom: 9px;
}

::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: lightgrey;
}

.frame-list::-webkit-scrollbar {
    width: 2px !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
    width: var(--slider-thumb-size) !important; 
    height: var(--slider-thumb-size) !important; 
    border-width: 2px !important; 
    border-color: black !important; 
    background-color: white !important;
}

mat-slider.mat-mdc-slider {
    height: var(--slider-input-height);
    width: 100%;
}

mat-slider input.mdc-slider__input {
    left: calc(var(--slider-input-height) / 2 * -1) !important;
    width: calc(100% + var(--slider-input-height)) !important;
}

mat-slider-visual-thumb.mdc-slider__thumb.mat-mdc-slider-visual-thumb {
    height: var(--slider-input-height);
    width: var(--slider-input-height);
    left: calc(var(--slider-input-height) / 2 * -1);
}

.mat-mdc-slider .mdc-slider__track--inactive {
    opacity: 1 !important;
}


.mat-mdc-button-wrapper {
    display: inline !important;
}

vcld-secure-image.order-image img {
    margin: 0;
    cursor: default !important;
    width: stretch !important;
    max-width: 200px;
}

vcld-image.gallery-image img,
vcld-secure-image.gallery-image img {
    margin-left: 10%;
}

vcld-image.min img {
    margin-left: 0px;
    margin-top: 12px;
}

vcld-image.tile img {
    margin-left: 0;
}

vcld-image.max img {
    margin-top: 0px;
}

a {
    text-decoration: none;
    color: black;
}

/* Disable corner rounding */
.sharpen {
    border-radius: 0px !important;
}

.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
    stroke: #008bd0;
}

/*
* Own opacities
*/

.o2 {
    opacity: 0.2;
}

.o4 {
    opacity: 0.4;
}

.o6 {
    opacity: 0.6;
}

.o8 {
    opacity: 0.8;
}

.o10 {
    opacity: 1;
}

/* COLOR PALETTE from CZV -> https://scene.zeplin.io/project/5b34f9f107f05967a06c1dec */

.error-panel {
    background-color: #f7f7f7;
    color: black;
}

.mat-mdc-snack-bar-action {
    color: #008bd0;
}

.success-panel {
    background-color: #00c48c;
}

/* Font-Color */
.text1 {
    color: #3e3e3e;
}
.text2 {
    color: #727272;
}
.text3 {
    color: #b4b4b4;
}
.text4 {
    color: #ffffff;
}

/* Background */
.bg01 {
    background-color: #008bd0;
}
.bg02 {
    background-color: #f8f8f8;
}
.bg03 {
    background-color: #efefef;
}
.bg04 {
    background-color: #d3d3d3;
}
.bg05 {
    background-color: #4b4e52;
}

.support-purplered {
    background: #a70240;
}
.support-purple-red-text {
    color: #a70240;
}

.support-inidgo {
    background: #141e8c;
}
.support-inidgo-text {
    color: #141e8c;
}

.support-azur {
    background: #055ed2;
}
.support-azur-text {
    color: #055ed2;
}

.support-skyblue {
    background: #6ab0e2;
}
.support-skyblue-text {
    color: #6ab0e2;
}

.support-green {
    background: #3c8a2e;
}
.support-green-text {
    color: #3c8a2e;
}

.support-neongreen {
    background: #e1ff32;
}
.support-neongreen-text {
    color: #e1ff32;
}

.support-lightgreen {
    background: #d9e906;
}
.support-lightgreen-text {
    color: #d9e906;
}

.support-orange {
    background: #ec6500;
}
.support-orange-text {
    color: #ec6500;
}

.support-brightorange {
    background: #ff1a00;
}
.support-brightorange-text {
    color: #ff1a00 !important;
}

.support-midnight {
    background: #00507b;
}
.support-midnight-text {
    color: #00507b;
}

.support-ocean {
    background: #00669c;
}
.support-ocean-text {
    color: #00669c;
}

vis-frame-selection-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.vcldTooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.favinventory-wrapper > .scrollable-content,
.recommendation-wrapper > .scrollable-content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--frame-gap);

    position: relative !important;
    max-height: unset !important;
}

@media only screen and (min-width: 201px) and (max-width: 601px) {
    .nose_ud_menu_right {
        left: 48px;
    }

    mat-tab-header {
        background: #fff;
        position: absolute !important;
        z-index: 1000;

        width: 75vw;
        max-width: 75vw;
    }

    vcld-secure-image.order-image img {
        max-width: 150px;
    }
    .cdk-global-overlay-wrapper{
        justify-content: center !important;
    }
}

@media only screen and (min-width: 640px) and (max-width: 979px) and (orientation: landscape) {
    vcld-secure-image.order-image img {
        max-width: 150px;
    }
}

@media only screen and (min-width: 602px) and (max-width: 992px) {
    mat-tab-header {
        z-index: 1000;

        width: 50vw;
        max-width: 50vw;
    }

    .mat-drawer-inner-container {
        overflow: auto;
        overflow-x: hidden !important;
    }
}

@media only screen and (min-width: 993px) {
    mat-tab-header {
        background: #fff;
        position: absolute !important;
        z-index: 1000;

        width: 33vw;
        max-width: 33vw;
    }

    .mat-mdc-tab {
        padding: 0px 12px !important;
    }
}

@media only screen and (min-width: 1201px) {
    mat-tab-header {
        background: #fff;
        position: absolute !important;
        z-index: 1000;

        width: 30vw;
        max-width: 30vw;
    }

    vcld-secure-image.order-image img {
        max-width: 250px;
    }

    .link-list .mdc-list-item__content {
        gap: var(--margin-medium);
    }
}

.viewer-tabs {
    // this is to fix issue with mat-tab with arrow(chevron) icons in viewer page.
    button.mat-mdc-tab-header-pagination-after,
    button.mat-mdc-tab-header-pagination-before {
        box-shadow: none !important;
    }
}

@media only screen and (min-width: 640px) and (max-width: 992px) {
    .viewer-tabs {
        // this is to fix issue with mat-tab with arrow(chevron) icons in viewer page.
        button.mat-mdc-tab-header-pagination-after,
        button.mat-mdc-tab-header-pagination-before{
            margin-bottom: 4px !important;
            margin-top: 4px !important;
        }
    }
}

.compare-view-tooltip {
    display: none;
}

@media only screen and (min-width: 201px) and (max-width: 601px) {
    .compare-view-tooltip {
        display: block;
    }
}

.vertical-slider {
    height: 34px;
    width: 168px;
}